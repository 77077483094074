$(document).on("ready turbolinks:load", function() {
  lightbox.init();
  setupEventFunctions();
});

function setupEventFunctions() {
  function drawCategoryTree(source, event_categories) {
    if (!source || !event_categories) {
      return;
    }
    source = JSON.parse(source);
    event_categories = JSON.parse(event_categories);

    var buffer = {};
    var search_autocomplete_arr = [];
    for (var i = 0; i < source.length; i++) {
      buffer[source[i].id] = {
        id: source[i].id,
        parent: source[i].parent_id,
        label: source[i].name,
        children: [],
      };
      search_autocomplete_arr.push({
        id: source[i].id,
        label: source[i].name,
        value: source[i].name,
      });
    }

    $("#search_field").autocomplete({
      source: search_autocomplete_arr,
      open: function(event, ui) {
        $(".ui-autocomplete").addClass("category-autocomplete");
        $(".ui-autocomplete").css("width", $("#search_field").outerWidth());
      },
      select: function(event, ui) {
        var treeVar = $("#tree1");
        var node = treeVar.tree("getNodeById", ui.item.id);
        addToSelectionWithParents(treeVar, node);
        if (node.children.length > 0) {
          treeVar.tree("openNode", node);
        } else {
          treeVar.tree("openNode", node.parent);
        }
        //Das Suchfeld wird geleert.
        event.preventDefault();
        $("#search_field").val("");
        selectedIDsToInput(treeVar);
      },
    });

    var mainNodeArr = [];
    for (var nodeID in buffer) {
      var node = buffer[nodeID];
      if (node.parent == null) {
        mainNodeArr.push(node);
      } else {
        buffer[node.parent].children.push(node);
      }
    }

    alphabeticalSort(mainNodeArr);

    var treeVar = $("#tree1");
    treeVar.tree({
      data: mainNodeArr,
      autoOpen: false,
      saveState: false,
      selectable: true,
      dragAndDrop: false,
      useContextMenu: false,
      keyboardSupport: false,
      onLoadFailed: function(response) {
        alert('<%= t("activerecord.errors.messages.cat_tree") %>');
      },
    });

    for (var index = 0; index < event_categories.length; index++) {
      var category_node = treeVar.tree("getNodeById", event_categories[index]);
      treeVar.tree("addToSelection", category_node);
      if (category_node.children.length > 0) {
        treeVar.tree("openNode", category_node);
      } else {
        treeVar.tree("openNode", category_node.parent);
      }
    }

    selectedIDsToInput(treeVar);

    treeVar.bind("tree.click", function(event) {
      //Einzelauswahl ist Standard -> deaktivieren
      event.preventDefault();

      var clicked_node = event.node;

      if (treeVar.tree("isNodeSelected", clicked_node)) {
        removeFromSelectionWithChildren(treeVar, clicked_node);
        treeVar.tree("closeNode", clicked_node);
      } else {
        addToSelectionWithParents(treeVar, clicked_node);
        treeVar.tree("openNode", clicked_node);
      }
      selectedIDsToInput(treeVar);
    });
  }

  function alphabeticalSort(arr) {
    arr.sort(function(a, b) {
      if (a.label < b.label) return -1;
      if (a.label > b.label) return 1;
      return 0;
    });
    for (var i = 0; i < arr.length; i++) {
      alphabeticalSort(arr[i].children);
    }
  }

  function addToSelectionWithParents(treeVar, node) {
    treeVar.tree("addToSelection", node);
    if (node.parent) {
      addToSelectionWithParents(treeVar, node.parent);
    }
  }

  function removeFromSelectionWithChildren(treeVar, node) {
    treeVar.tree("removeFromSelection", node);
    for (var i = 0; i < node.children.length; i++) {
      removeFromSelectionWithChildren(treeVar, node.children[i]);
    }
  }

  function resetTree() {
    $("#tree1").tree("destroy");
  }

  function addSelectedNodeIDsToArr(treeVar, node, arr) {
    if (node === null) {
      return;
    }
    for (var i = 0; i < node.children.length; i++) {
      if (treeVar.tree("isNodeSelected", node.children[i])) {
        arr.push(node.children[i].id);
        addSelectedNodeIDsToArr(treeVar, node.children[i], arr);
      }
    }
  }

  function selectedIDsToInput(treeVar) {
    var root = treeVar.tree("getTree");
    var arr = [];
    addSelectedNodeIDsToArr(treeVar, root, arr);
    arr.sort(function(a, b) {
      return a - b;
    });
    $("#submitButton").val("IDs anschauen (" + arr.length + " ausgewählt)");
    $("#post_categories").empty();
    for (var i = 0; i < arr.length; i++) {
      $("#post_categories").append(
        '<input type="hidden" value="' +
          arr[i] +
          '" name="event[category_ids][]" readonly>'
      );
    }
  }

  //
  //
  //
  //
  //
  //

  resetTree();
  var event_categories = $('input[name="event_categories"]').val();
  var source = $('input[name="source"]').val();
  drawCategoryTree(source, event_categories);

  $(".event-grid-index-header").click(function() {
    $(".event-grid-index-header").removeClass("activated");
    $(this).addClass("activated");
  });

  $(".datepicker").datepicker({
    format: "dd.mm.yyyy",
    todayHighlight: true,
    language: "de-DE",
  });

  $.fn.dataTable.moment("DD.MM.YYYY");
  $.fn.dataTable.moment("D.MM.YYYY");
  $.fn.dataTable.moment("DD.M.YYYY");
  $.fn.dataTable.moment("D.M.YYYY");
  $(".dataTable").dataTable({
    lengthChange: false,
    language: {
      url: "/assets/shared/dataTables.german.lang",
    },
    order: [],
  });

  // mobileThreshold is the x size under which we display the functions with
  // 100% width of the browser window
  //
  var mobileThreshold = 600;
  //
  //

  var sticky_functions = $(".sticky-functions");
  var form_data = $(".begin-form-data");
  srolled = "scrolled";
  gapToContainer = 30;
  var headerHeight = $(".main-header").height() + gapToContainer;
  var contentWidth = $(".content").width();

  $(window).resize(function() {
    var headerHeight = $(".main-header").height();
    sticky_functions.removeClass(srolled);
  });

  $(window).scroll(function() {
    // for x resolution higher than mobile threshold
    if ($(window).width() > mobileThreshold) {
      var contentWidth = $(".content").width();
      if ($(this).scrollTop() > headerHeight) {
        $(".sticky-functions.scrolled").css("left", "auto");
        $(".sticky-functions.scrolled").css("right", "auto");
        sticky_functions.addClass(srolled);
        $(".sticky-functions.scrolled").css("width", contentWidth + "px");
        form_data.addClass("top-margin");
      } else {
        sticky_functions.removeClass(srolled);
        $(".sticky-functions").css("width", "auto");
        form_data.removeClass("top-margin");
      }
    } else {
      // for small mobile screens
      if ($(this).scrollTop() > headerHeight) {
        sticky_functions.addClass(srolled);
        $(".sticky-functions.scrolled").css("left", "0");
        $(".sticky-functions.scrolled").css("right", "0");
        form_data.addClass("top-margin");
      } else {
        sticky_functions.removeClass(srolled);
        form_data.removeClass("top-margin");
      }
    }
  });
}
