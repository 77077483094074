$(document).on("ready turbolinks:load", function() {
  $(".more-search-options, .less-search-options").click(function() {
    $(
      ".optional-search-fields, .less-search-options, .more-search-options"
    ).toggleClass("hidden");
    if (Cookies.get("optional_search") == "hidden") {
      Cookies.set("optional_search", "");
    } else {
      Cookies.set("optional_search", "hidden");
    }
  });
});
