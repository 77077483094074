$(document).ready(function() {
  //
  // Make sure link is not triggered on mobile devices
  //
  $(".multi-level > .dropdown-submenu > a.multi-level-toggle").click(function(
    e
  ) {
    e.preventDefault();
    e.stopPropagation();
  });

  $("body").append(
    '<div id="confirmDialog" class="modal fade" role="dialog">' +
      '<div class="modal-dialog"> <div class="modal-content"> ' +
      '<div class="modal-header">' +
      '<button type="button" class="close" data-dismiss="modal">&times;</button>' +
      '<h4 class="modal-title">Bist du sicher?</h4> </div>' +
      '<div class="modal-footer">' +
      '<button type="button" class="btn btn-flat btn-default" data-dismiss="modal">Abbrechen</button> ' +
      '<button type="button" class="btn btn-flat btn-danger" id="confirmed" data-dismiss="modal">Ok</button> ' +
      "</div> </div> </div> </div>"
  );

  //Override the default confirm dialog by rails
  $.rails.allowAction = function(link) {
    if (link.data("confirm") == undefined) {
      return true;
    }
    $.rails.showConfirmationDialog(link);
    return false;
  };

  //User click confirm button
  $.rails.confirmed = function(link) {
    link.data("confirm", null);
    link.trigger("click.rails");
  };

  $.rails.showConfirmationDialog = function(link) {
    $("#confirmed").click(function() {
      $.rails.confirmed(link);
    });

    $("#confirmDialog").modal();
  };

  $(".toggle-embed-code").click(function() {
    $(".embed-code").toggleClass("hidden");
  });

  $(".browser-back").click(function(e) {
    e.stopPropagation();
    window.history.back();
  });

  $(".check-file-size").bind("change", function(e) {
    for (var i = 0, len = this.files.length; i < len; i++) {
      if (this.files[i].size > 4999999) {
        alert($(this).data("error-too-large") + "\n " + this.files[i].name);
        $(this).val(null);
      }
    }
  });
});
