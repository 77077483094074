/* eslint no-console:0 */

import Turbolinks from "turbolinks";
import Rails from "@rails/ujs";

// Expose jQuery to js in views
window.jQuery = $;
window.$ = $;

import Cookies from "js-cookie/index.js";
window.Cookies = Cookies;

import "jquery-ui/ui/widgets/autocomplete";
import "bootstrap/dist/js/bootstrap.min.js";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-datepicker/dist/js/bootstrap-datepicker.js";
import "bootstrap-datepicker/dist/css/bootstrap-datepicker3.min.css";
import "@fortawesome/fontawesome-free/css/all";
import "@fortawesome/fontawesome-free/css/all";
import "lightbox2/dist/css/lightbox.css";

import "trix/dist/trix.js";
import "trix/dist/trix.css";
import "../../assets/javascripts/features/trix/trix-config";

import "codemirror/lib/codemirror.css";
import "codemirror/theme/night.css";
import "codemirror/mode/css/css.js";

Turbolinks.start();
Rails.start();

let sharedJs = require.context(
  "../../assets/javascripts/shared",
  true,
  /\.js$/
);
for (let key of sharedJs.keys()) {
  sharedJs(key);
}

let webpackContext = require.context(
  "../../assets/javascripts",
  false,
  /\.js$/
);
for (let key of webpackContext.keys()) {
  webpackContext(key);
}

// Images
require.context("../../assets/images", true, /\.(?:png|jpg|gif|ico|svg)$/);

// Flags
require.context(
  "../../assets/images/flags",
  true,
  /\.(?:png|jpg|gif|ico|svg)$/
);

// Stylesheets
require.context("../../assets/stylesheets", true, /\.(?:sass|scss|css)$/);
