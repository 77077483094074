$(document).ready(function() {
  if ($("#new_user").length != 0) {
    // It is the sign up page...
    try {
      showPaymentFields();
      showFieldsForIbanAndBic();
    } catch (e) {
      /* handle error */
    }
  }

  $("#change-password").click(function() {
    $(".change-password").toggleClass("hidden");
  });

  $(".change-payment-method").change(function() {
    showFieldsForIbanAndBic();
  });

  $(".change-account-type").change(function() {
    showPaymentFields();
  });
});

function showPaymentFields() {
  var selected = $(".change-account-type option:selected");
  if (selected !== undefined) {
    text = selected.text();
  }
  if (text == "Registrierter Benutzer - 0,00 €/Monat") {
    $(".payment-methods").addClass("hidden");
  } else {
    $(".payment-methods").removeClass("hidden");
  }
}

function showFieldsForIbanAndBic() {
  var selected = $(".change-payment-method option:selected");
  if (selected !== undefined) {
    value = selected[0].value;
  }
  if (value == "paypal" || value == "") {
    $(".iban-and-bic").addClass("hidden");
  } else {
    $(".iban-and-bic").removeClass("hidden");
  }
}
