$(document).ready(function() {
  editor_area = document.getElementById("css-editor");
  if (editor_area) {
    var editor = CodeMirror.fromTextArea(editor_area, {
      lineNumbers: true,
      theme: "night",
    });
    $("#css-editor").data("CodeMirror", editor);
  }
});
